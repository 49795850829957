.admin_material_formbox{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}
.admin_material_formbox h3{
    font-size: 14px;
    font-weight: 500;
}
.admin_material_row{
    display: flex;
    justify-content: space-between;
}
.admin_material_col50{
    width: 49.5%;
}