.labadmin_messages_formbox{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}
.labadmin_messages_row{
    display: flex;
    justify-content: space-between;
}
.labadmin_messages_col30{
    width: 29.5%;
}
.labadmin_messages_col70{
    width: 69.5%;
}