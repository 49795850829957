.public_privacypolicy_content_container{
    max-width: 1080px;
    margin: auto;
    padding: 50px 0px;
}
.public_privacypolicy_content_container h3{
    font-size: 18px;
    font-weight: 500;
    padding: 20px 0px;
}
.public_privacypolicy_content_container p{
    padding: 5px 0px;
    font-size: 12px;
}
.public_privacypolicy_content_container ul{
    padding: 20px 0px;
}
.public_privacypolicy_content_container ul li{
    font-size: 12px;
    padding: 0px 20px;
}