.labadmin_orders_new_formbox{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}
.labadmin_orders_new_row{
    display: flex;
    justify-content: space-between;
}
.labadmin_orders_new_col50{
    width: 49.5%;
}
.labadmin_orders_new_col33{
    width: 32.5%;
}