.labadmin_messages_userselector_container{
    height: 600px;
    overflow-y: auto;
    margin: 0px 10px;
}
.labadmin_messages_userselector_user_container{
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
}
.labadmin_messages_userselector_user_container:hover{
    background-color: #ededed;
}
.labadmin_messages_userselector_user_container.active{
    background-color: #ededed;
}
.labadmin_messages_userselector_user_container svg{
    width: 20px;
    height: 20px;
    padding: 10px;
}
.labadmin_messages_userselector_user_container p{
    font-size: 10px;
    width: 100%;
}
