.public_primary_btn button{
    background-color: transparent;
    outline: none;
    border: none;
}
.public_primary_btn_container{
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    width: 150px;
    height: 50px;
}
.public_primary_btn_text{
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.public_primary_btn_text p{
    font-weight: 500;
}
.public_primary_btn_background{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 0;
    transition: all 500ms;
    border-radius: 10px;
}
.public_primary_btn_container:hover .public_primary_btn_background{
    width: 100%;
}

.public_primaryalt_btn button{
    background-color: transparent;
    outline: none;
    border: none;
    width: 100%;
}
.public_primaryalt_btn_container{
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 50px;
}
.public_primaryalt_btn_text{
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.public_primaryalt_btn_text p{
    font-weight: 500;
}
.public_primaryalt_btn_background{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 0;
    transition: all 500ms;
    border-radius: 10px;
}
.public_primaryalt_btn_container:hover .public_primaryalt_btn_background{
    width: 100%;
}


.public_primary_input_container{
    margin: 10px 0px;
    border-radius: 10px;
}
.public_primary_input_container input{
    padding: 10px 20px;
    outline: none;
    border: none;
    border-radius: 10px;
    width: 100%;
}

.public_primary_textarea_container{
    margin: 10px 0px;
}
.public_primary_textarea_container textarea{
    padding: 10px 20px;
    outline: none;
    border-radius: 10px;
    width: 100%;
    min-height: 200px;
}