.admin_labs_formbox{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}
.admin_labs_formbox h3{
    font-size: 14px;
    font-weight: 500;
}
.admin_labs_row{
    display: flex;
    justify-content: space-between;
}
.admin_labs_col35{
    width: 34.5%;
}
.admin_labs_col65{
    width: 64.5%;
}
.admin_labs_col50{
    width: 49.5%;
}
.admin_labs_col33{
    width: 32.5%;
}
.admin_labs_divider{height: 20px;}
@media screen and (max-width: 620px) {
    .admin_labs_row{
        flex-wrap: wrap;
    }
    .admin_labs_col35{
        width: 100%;
    }
    .admin_labs_col65{
        width: 100%;
    }
    .admin_labs_col50{
        width: 100%;
    }
    .admin_labs_col33{
        width: 100%;
    }
}