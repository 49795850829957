.labadmin_doctors_clinics_formbox{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}
.labadmin_doctors_clinics_row{
    display: flex;
    justify-content: space-between;
}
.labadmin_doctors_clinics_col50{
    width: 49.5%;
}
@media screen and (max-width: 620px) {
    .labadmin_doctors_clinics_row{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .labadmin_doctors_clinics_col50{
        width: 100%;
    }

}