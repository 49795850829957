.panelcontrols_panelbutton_second button{
    background-color: #d9d9d9;
    outline: none;
    border: 1px solid #d9d9d9;
    width: 100%;
    color: black;
    border-radius: 5px;
    padding: 8px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}
.panelcontrols_panelbutton_second button:hover{
    background-color: white;
}