.labs_information_container{
    max-width: 1080px;
    padding: 30px 20px;
    margin: auto;
}
.labs_information_row{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}
.labs_information_col{
    width: 32%;
}
.labs_information_col_border{
    border-right: 2px dashed #2f1c60;
}
.labs_information_locations_item{
    display: flex;
    padding: 30px 0px;
    align-items: center;
}
.labs_information_locations_item_icon svg{
    width: 50px;
    height: 50px;
}
.labs_information_locations_item_icon svg path{
    fill: #2f1c60;
}
.labs_information_locations_item_details{
    padding: 0px 10px;
}
.labs_information_locations_item_details h3{
    font-size: 24px;
    font-weight: 500;
    padding: 10px 0px;
    color: #2f1c60;
}
.labs_information_locations_item_details p{
    padding: 5px 0px;
}
@media screen and (max-width: 620px) {
    .labs_information_row{
        flex-wrap: wrap;
    }
    .labs_information_col{
        width: 100%;
    }
    .labs_information_col_border{
        border-bottom: 2px dashed #2f1c60;
        border-right: none;
    }
}