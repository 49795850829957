.public_faq_wrapper{
    padding: 200px 0px;
}
.public_faq_container{
    max-width: 1080px;
    margin: auto;
}
.public_faq_qna_container{
    margin: 20px 0px;
    border-radius: 10px;
}
.public_faq_qna_question{
    padding: 20px;
    cursor: pointer;
}
.public_faq_qna_question_row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.public_faq_qna_question_row h3{
    font-weight: 500;
}
.public_faq_qna_question_arrow{
    transition: all 200ms;
}
.public_faq_qna_question_arrow.active{
    transform: rotate(180deg);
}
.public_faq_qna_answer{
    padding: 0px 30px;
    height: 0;
    overflow: hidden;
    transition: all 200ms;
}
.public_faq_qna_answer.active{
    height: auto;
    padding: 0px 30px 20px 30px;
}