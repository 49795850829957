.admin_expense_formbox{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}
.admin_expense_row{
    display: flex;
    justify-content: space-between;
}
.admin_expense_col50{
    width: 49.5%;
}