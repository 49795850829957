.public_login_wrapper{
    padding: 100px 20px;
}
.public_login_container{
    max-width: 500px;
    margin: auto;
    border-radius: 20px;
    padding-bottom: 5px;
}
.public_login_form{
    border-radius: 20px;
    padding: 30px;
    border-radius: 20px;
    margin: 0px 0px 0px 5px;
}
.public_login_form h3{
    font-size: 24px;
    padding: 20px 0px;
}