.public_contact_form_wrapper{
    padding: 100px 0px;
}
.public_contact_form_container{
    max-width: 1080px;
    margin: auto;
}
.public_contact_form_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.public_contact_form_locations{
    width: 40%;
}
.public_contact_form_locations_item{
    display: flex;
    padding: 30px 0px;
}
.public_contact_form_locations_item_icon svg{
    width: 50px;
    height: 50px;
}
.public_contact_form_locations_item_details{
    padding: 0px 10px;
}
.public_contact_form_locations_item_details p{
    padding: 5px 0px;
}
.public_contact_form_inputs{
    width: 50%;
    padding: 50px;
    border-radius: 20px;
}
.public_contact_email_wrapper{
    padding: 50px 0px;
}
.public_contact_email_container{
    max-width: 1080px;
    margin: auto;
    padding: 30px;
    border-radius: 20px;
}
.public_contact_email_row{
    display: flex;
}

@media screen and (max-width: 620px) {
    .public_contact_form_row{
        flex-wrap: wrap;
    }
    .public_contact_form_locations{
        width: 100%;
        padding: 0px 20px;
    }
    .public_contact_form_inputs{
        width: 100%;
        padding: 40px 20px;
        margin: 50px 0px;
    }
    .public_contact_email_row{
        flex-wrap: wrap;
    }
    .public_contact_email_button{
        margin: 20px auto;
    }
}