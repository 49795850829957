.public_about_hero_wrapper{
    border-radius: 0px 0px 0px 200px;
    padding: 20px 0px 100px 0px;
}
.public_about_hero_container{
    max-width: 1080px;
    margin: auto;
}
.public_about_hero_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.public_about_hero_text{
    width: 65%;
    padding: 100px 0px;
}
.public_about_hero_text h3{
    font-size: 34px;
    padding: 20px 0px;
}
.public_about_hero_clicks{
    width: 25%;
}
.public_about_hero_clicks_login{
    width: 90%;
    margin: auto;
}
.public_about_hero_clicks_login a{
    color: white;
    font-size: 12px;
}
.public_about_hero_clicks_login a button{
    width: 100%;
    padding: 20px 0px;
    background-color: transparent;
    outline: none;
    border: none;
    color: white;
    border: 2px solid white;
    cursor: pointer;
    border-radius: 10px;
}
.public_about_hero_clicks_ceo{
    padding: 20px;
}
.public_about_hero_clicks_ceo h3{
    font-size: 12px;
    text-align: center;
    color: white;
}
.public_about_hero_clicks_ceo h3 b{
    font-size: 20px;
    text-align: center;
    color: white;
}
.public_about_hero_clicks_login p{
    text-align: center;
    color: white;
    padding: 10px 0px;
    font-size: 12px;
}
.public_about_hero_clicks_icons{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 40px 0px 0px 0px;
}
.public_about_hero_clicks_icon svg{
    cursor: pointer;
    width: 20px;
    height: 20px;    
}
.public_about_hero_clicks_icon svg path{
    fill: white;
}

@media screen and (max-width: 620px) {
   .public_about_hero_row{
    flex-wrap: wrap;
   } 
   .public_about_hero_text{
    width: 100%;
    padding: 0px 20px;
   }
   .public_about_hero_clicks{
    width: 100%;
    padding: 0px 40px;
    margin: 40px 0px;
   }
}