.public_home_samples_container{
    margin: 150px auto 150px auto;
    max-width: 1080px;
}
.public_home_samples_row{
    display: flex;
    justify-content: space-between;
}
.public_home_samples_col35{
    width: 35%;
}
.public_home_samples_col60{
    width: 60%;
}
.public_home_samples_Images_container{
    max-width: 1080px;
    margin: auto;
}
.public_home_samples_Image_container{
    display: flex;
    justify-content: center;
}
.public_home_samples_Image_container img{
    width: 70%;
    border-radius: 10px;
}
.public_home_samples_details_container h3{
    font-size: 32px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 10px 0px;
}
.public_home_samples_details_container p{
    padding: 5px 0px;
    line-height: 1.6;
}

@media screen and (max-width: 620px) {
    .public_home_samples_row{
        flex-wrap: wrap;
    }
    .public_home_samples_col35{
        width: 100%;
        padding: 0px 20px;
    }   
    .public_home_samples_details_container h3{
        text-align: center;
    }
    .public_home_samples_col60{
        width: 100%;
        margin: 50px 0px;
    }
}