.admin_layout_rightbar_wrapper{
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 25vw;
    background-color: white;
    border-radius: 10px 0px 0px 10px;
    padding: 20px;
}
.admin_layout_rightbar_logout_container{
    padding: 5px 0px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
}
.admin_layout_rightbar_logout_icon svg{
    width: 16px;
    height: 16px;
}
.admin_layout_rightbar_logout_icon svg path{
    fill: #c0c0c0;
}
.admin_layout_rightbar_logout_lable p{
    font-size: 14px;
    font-weight: 500;
    color: #929292;
}

.admin_layout_rightbar_logout_container:hover .admin_layout_rightbar_logout_icon svg path{
    fill: #5c3da9;
}
.admin_layout_rightbar_logout_container:hover .admin_layout_rightbar_logout_lable p{
    color: #5c3da9;
}
.admin_layout_rightbar_user_container{
    margin: 20px 0px;
}
.admin_layout_rightbar_user_image_wrapper{
    width: 60%;
    margin: auto;
    padding-top: 60%;
    background-color: #F5F5FB;
    border-radius: 50%;
    position: relative;
}
.admin_layout_rightbar_user_image_container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin_layout_rightbar_user_image_container svg{
    width: 100px;
    height: 100px;
}
.admin_layout_rightbar_user_image_container svg path{
    fill: #9a9a9a;
}
.admin_layout_rightbar_user_details{
    padding: 15px 0px;
}
.admin_layout_rightbar_user_details h4{
    text-align: center;
    text-transform: uppercase;
    color: #5c3da9;
    font-weight: 500;
    font-size: 18px;
}
.admin_layout_rightbar_user_details p{
    color: #9a9a9a;
    font-size: 12px;
    text-align: center;
}
.admin_layout_rightbar_reminders_container{
    margin: 20px 0px;
    padding: 10px 0px;
}
.admin_layout_rightbar_reminders_title{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.admin_layout_rightbar_reminders_title p{
    font-size: 14px;
    font-weight: 500;
    color: #2a2a2a;
}
.admin_layout_rightbar_reminders_add{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #c0c0c0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin_layout_rightbar_reminders_add svg{
    width: 16px;
    height: 16px;
}
.admin_layout_rightbar_reminders_add svg path{
    fill: white;
}
.admin_layout_rightbar_reminders_add:hover{
    background-color: #5c3da9;
    cursor: pointer;
}