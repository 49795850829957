.labadmin_orders_new_lineitems_add_formbox{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}
.labadmin_orders_new_lineitems_add_row{
    display: flex;
    justify-content: space-between;
}
.labadmin_orders_new_lineitems_add_col50{
    width: 49.5%;
}
.labadmin_orders_new_lineitems_add_col35{
    width: 34.5%;
}
.labadmin_orders_new_lineitems_add_col65{
    width: 64.5%;
}
.labadmin_orders_new_lineitems_add_col33{
    width: 32.5%;
}