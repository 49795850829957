.admin_layout_body_wrapper{
    min-height: 100vh;
    background-color: #F5F5FB;
    padding: 30px 7vw;
    width: 90vw;
    margin: 0px 0vw 0px 10vw;
}
.mobile_menu_opener{
    display: none;
}

@media screen and (max-width: 620px) {
    .admin_layout_body_wrapper{
        min-height: 100vh;
        background-color: #F5F5FB;
        padding: 30px 20px;
        width: 100vw;
        margin: 0px 0vw 0px 0vw;
    }
    .mobile_menu_opener{
        display: flex;
        justify-content: flex-end;
    }
    .mobile_menu_opener button{
        outline: none;
        background-color: transparent;
        border: none;
    }
}