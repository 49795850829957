.public_topbar_container{
    padding: 10px;
}
.public_topbar_container p{
    text-align: center;
    font-weight: 500;
}
.public_header_wrapper{
    border-radius: 50px 50px 0px 0px;
    padding: 20px 50px;
}
.public_header_container{
    max-width: 1080px;
    margin: auto;
}
.public_header_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.public_header_logonav{
    display: flex;
    align-items: center;
}
.public_header_logo{
    padding: 10px 0px;
    cursor: pointer;
}
.public_header_logo img{
    width: 200px;
}
.public_header_nav{
    display: flex;
    padding: 0px 10px;
}
.public_header_item_wrapper{
    position: relative;
}
.public_header_item_wrapper:hover .public_header_item_list_container{
    display: block;
}
.public_header_item_container{
    padding: 0px 20px;
}
.public_header_item_text p{
    font-size: 16px;
    padding: 5px 0px;
}
.public_header_item_bar{
    height: 2px;
    width: 0px;
    transition: all 500ms;
    border-radius: 1px;
}
.public_header_item_container:hover .public_header_item_bar{
    width: 100%;
}
.public_header_buttons{
    display: flex;
    align-items: center;
}
.public_header_item_list_container{
    position: absolute;
    left: 2px;
    right: 2px;
    padding: 10px 3px;
    background-color: white;
    border-radius: 5px;
    display: none;
    width: 150%;
}
.public_header_item_list_container .public_header_item_text p{
    font-size: 10px;
}
.public_header_item_sublist_container{
    position: absolute;
    left: 100%;
    top: 0;
    padding: 10px 3px;
    background-color: white;
    border-radius: 5px;
    display: none;
    width: 100%;
    border: 2px solid #2f1c60;
}
.public_header_list_item_wrapper:hover .public_header_item_sublist_container{
    display: block;
}

.public_footer_wrapper{
    border-radius: 50px 50px 0px 0px;
    padding: 100px 50px;
}
.public_footer_container{
    max-width: 1080px;
    margin: auto;
}
.public_footer_container h3{
    font-weight: 500;
    padding: 20px 0px 30px 0px;
}
.public_footer_row{
    display: flex;
}
.public_footer_company{
    width: 25%;
}
.public_footer_logo{
    padding: 10px 0px;
    cursor: pointer;
}
.public_footer_logo img{
    width: 70%;
}
.public_footer_nav{
    width: 15%;
}

.public_footer_nav_container{

}
.public_footer_item_wrapper{
    display: flex;
}
.public_footer_item_container{
    padding: 5px 0px;
}
.public_footer_item_text p{
    font-size: 16px;
    padding: 5px 0px;
}
.public_footer_item_bar{
    height: 2px;
    width: 0px;
    transition: all 500ms;
    border-radius: 1px;
}
.public_footer_item_container:hover .public_footer_item_bar{
    width: 100%;
}

.public_footer_contact{
    width: 45%;
}
.public_footer_contact_item{
    display: flex;
    padding: 10px;
    cursor: pointer;
}
.public_footer_contact_item_social{
    display: flex;
    padding: 10px;
    cursor: pointer;
}
.public_footer_contact_item_social .public_footer_contact_item_icon svg{
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
.public_footer_contact_item_icon svg{
    width: 20px;
    height: 20px;
}
.public_footer_contact_item_text{
    padding: 0px 15px;
}
.public_footer_newsletter{
    width: 30%;
}

.public_bottombar_container{
    padding: 30px 20px;
}
.public_bottombar_container p{
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}
.public_bottombar_container p a{
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}

.public_header_complete{
    border-radius: 0px 0px 50px 50px;
    padding: 20px 30px;
}


/*Mobile*/

.public_header_mobile_wrapper{
    border-radius: 20px 20px 0px 0px;
    padding: 10px 20px;
}
.public_header_mobile_container{
    max-width: 1080px;
    margin: auto;
}
.public_header_mobile_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.public_header_mobile_logo{
    padding: 10px 0px;
    cursor: pointer;
}
.public_header_mobile_logo img{
    width: 30vw;
}
.public_header_mobile_navopener button{
    border: none;
    outline: none;
    background-color: transparent;
}
.public_header_mobile_navopener svg{
    width: 5vw;
    height: 5vw;
}
.public_header_mobile_navopener svg path{
    fill: white;
}
.public_header_mobile_nav_wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.public_header_mobile_nav_container{
    padding: 20px;
}
.public_header_mobile_nav_items p{
    text-align: center;
    padding: 15px;
}
.public_header_mobile_nav_button{
    padding: 60px 0px 0px 0px;
}
.public_header_mobile_nav_close{
    position: absolute;
    background-color: white;
    border-radius: 50%;
    padding: 10px;
    top: 20px;
    right: 20px;
    cursor: pointer;
}
.public_header_mobile_nav_close button{
    background-color: transparent;
    border: none;
    outline: none;
}
.public_header_mobile_nav_close svg{
    width: 20px;
    height: 20px;
}

@media screen and (max-width: 1020px) {
    .public_footer_wrapper{
        border-radius: 20px 20px 0px 0px;
        padding: 100px 50px;
    }
    .public_footer_container{
        max-width: 1080px;
        margin: auto;
    }
    .public_footer_container h3{
        font-weight: 500;
        padding: 20px 0px 30px 0px;
    }
    .public_footer_row{
        display: flex;
        flex-wrap: wrap;
    }
    .public_footer_company{
        width: 33.33%;
    }
    .public_footer_logo{
        padding: 10px 0px;
        cursor: pointer;
    }
    .public_footer_logo img{
        width: 70%;
    }
    .public_footer_nav{
        width: 33.33%;
        margin: 10px 0px;
    }
    
    .public_footer_nav_container{
    
    }
    .public_footer_item_wrapper{
        display: flex;
    }
    .public_footer_item_container{
        padding: 5px 0px;
    }
    .public_footer_item_text p{
        font-size: 16px;
        padding: 5px 0px;
    }
    .public_footer_item_bar{
        height: 2px;
        width: 0px;
        transition: all 500ms;
        border-radius: 1px;
    }
    .public_footer_item_container:hover .public_footer_item_bar{
        width: 100%;
    }
    
    .public_footer_contact{
        width: 33.33%;
        margin: 10px 0px;
    }
    .public_footer_contact_item{
        display: flex;
        padding: 10px;
        cursor: pointer;
    }
    .public_footer_contact_item_icon svg{
        width: 20px;
        height: 20px;
    }
    .public_footer_contact_item_text{
        padding: 0px 15px;
    }
    .public_footer_newsletter{
        width: 100%;
        margin: 10px 0px;
    }
}

@media screen and (max-width: 620px) {
    .public_footer_wrapper{
        border-radius: 20px 20px 0px 0px;
        padding: 100px 50px;
    }
    .public_footer_container{
        max-width: 1080px;
        margin: auto;
    }
    .public_footer_container h3{
        font-weight: 500;
        padding: 20px 0px 30px 0px;
    }
    .public_footer_row{
        display: flex;
        flex-wrap: wrap;
    }
    .public_footer_company{
        width: 100%;
    }
    .public_footer_logo{
        padding: 10px 0px;
        cursor: pointer;
    }
    .public_footer_logo img{
        width: 70%;
    }
    .public_footer_nav{
        width: 100%;
        margin: 10px 0px;
    }
    
    .public_footer_nav_container{
    
    }
    .public_footer_item_wrapper{
        display: flex;
    }
    .public_footer_item_container{
        padding: 5px 0px;
    }
    .public_footer_item_text p{
        font-size: 16px;
        padding: 5px 0px;
    }
    .public_footer_item_bar{
        height: 2px;
        width: 0px;
        transition: all 500ms;
        border-radius: 1px;
    }
    .public_footer_item_container:hover .public_footer_item_bar{
        width: 100%;
    }
    
    .public_footer_contact{
        width: 100%;
        margin: 10px 0px;
    }
    .public_footer_contact_item{
        display: flex;
        padding: 10px;
        cursor: pointer;
    }
    .public_footer_contact_item_icon svg{
        width: 20px;
        height: 20px;
    }
    .public_footer_contact_item_text{
        padding: 0px 15px;
    }
    .public_footer_newsletter{
        width: 100%;
        margin: 10px 0px;
    }
}