@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1.5px;
    color: #2a2a2a;
    text-decoration: none;
}
body{
    min-height: 100vh;
    background-color: white;
}

.colorprimary{
    color: #2f1c60;
}
.colorprimarybg{
    background-color: #2f1c60;
}
.colorprimaryhover :hover{
    color: #2f1c60;
}
.colorprimarybghover :hover{
    background-color: #2f1c60;
}

.colorwhite{
    color: white;
}
.colorwhitebg{
    background-color: white;
}
.colorwhitehover :hover{
    color: white;
}
.colorwhitebghover :hover{
    background-color: white;
}

.colorsecondary{
    color: #f5f5f5;
}
.colorsecondarybg{
    background-color: #f5f5f5;
}
.colorsecondaryhover :hover{
    color: #f5f5f5;
}
.colorsecondarybghover :hover{
    background-color: #f5f5f5;
}

/*Components*/
.public_primary_btn_container{
    border: 2px solid white;
    background-color: white;
}
.public_primary_btn_text{
    background-color: transparent;
}
.public_primary_btn_text p{
    color: #2f1c60;
}
.public_primary_btn_container:hover .public_primary_btn_text p{
    color: white;
}
.public_primary_btn_background{
    background-color: #2f1c60;
}

.public_primaryalt_btn_container{
    border: 2px solid #2f1c60;
    background-color: #2f1c60;
}
.public_primaryalt_btn_text{
    background-color: transparent;
}
.public_primaryalt_btn_text p{
    color: white;
}
.public_primaryalt_btn_container:hover .public_primaryalt_btn_text p{
    color: #2f1c60;
}
.public_primaryalt_btn_background{
    background-color: white;
}
.public_primary_input_container{
    border: 2px solid #2f1c60;
}
.public_footer_wrapper{
    border-top: 2px solid #2f1c60;

}
.public_footer_contact_item_icon svg path{
    fill: #2a2a2a;
}
.public_home_services_item_container{
    background-color: #f5f5f5;
}
.public_home_services_seemore_container{
    background-color: #f5f5f5;
}

.public_home_facts_wrapper{
    background-color: #f5f5f5b5;
}

.public_home_facts_item_image svg path{
    fill: #2f1c60;
}
.public_home_testimonials_item_image{
    border: 5px solid #2f1c60;
}
.public_faq_qna_question svg path{
    fill: #2f1c60;
}
.public_contact_form_locations_item{
    border-bottom: 2px dashed #2f1c60;
}
.public_contact_form_locations_item_icon svg path{
    fill: #2f1c60;
}

.public_primary_textarea_container textarea{
    border: 2px solid #2f1c60;
}
.public_servic_aligners_imageA, .public_servic_aligners_imageB{
    border: 3px solid #2f1c60;
}
.public_servic_dentalimplants_imageA, .public_servic_dentalimplants_imageB{
    border: 3px solid #f5f5f5;
}
/*Components End*/


