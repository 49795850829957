.panelcontrols_paneltablebuttons_print button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    background-color: rgb(0, 128, 255);
    outline: none;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}
.panelcontrols_paneltablebuttons_print button svg{
    height: 10px;
    width: 10px;
}
.panelcontrols_paneltablebuttons_print button svg path{
    fill: white;
}
