.panel_watermark_wrapper{
    background-color: white;
}
.panel_watermark_contaier{
    padding: 10px 20px;
}
.panel_watermark_contaier p{
    font-size: 12px;
    color: #c0c0c0;
    text-align: center;
}
.panel_watermark_contaier h5{
    font-size: 12px;
    color: #5c3da9;
    text-align: center;
    font-weight: 600;
}
