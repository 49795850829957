.panelcontrols_select_main_container{
    padding: 5px 0px;
}
.panelcontrols_select_main_container label{
    width: 100%;
}
.panelcontrols_select_main_container .panelcontrols_select_wrapper{
    width: 100%;
    margin: 2px 0px;
}
.panelcontrols_select_wrapper{
    position: relative;
    height: 32px;
}
.panelcontrols_select_container{
    position: absolute;
    width: 100%;
    cursor: pointer;
}
.panelcontrols_select_value{
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
}
.panelcontrols_select_options{
    position: absolute;
    top: 110%;
    left: 0;
    padding: 5px 10px;
    display: none;
    width: 100%;
    max-height: 150px;
    overflow: auto;
    z-index: 3;
}
.panelcontrols_select_wrapper.active .panelcontrols_select_options{
    display: block;
}
.panelcontrols_select_option{
    padding: 5px;
}

.panelcontrols_select_main_container label{
    font-size: 12px;
}
.panelcontrols_select_wrapper .panelcontrols_select_value{
    outline: none;
    border: 1px solid #cbcbcb;
    border-radius: 3px;
}
.panelcontrols_select_wrapper .panelcontrols_select_value h6{
    font-size: 12px;
    pointer-events: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.panelcontrols_select_wrapper .panelcontrols_select_value h6::after{
    content: '\25be';
}
.panelcontrols_select_wrapper.active .panelcontrols_select_value{
    border: 1px solid #5C3DA9;
    border-radius: 3px;
}
.panelcontrols_select_options{
    border-radius: 3px;
    background-color: white;
    -webkit-box-shadow: 0 0 2px #2a2a2a;
        box-shadow: 0 0 2px #2a2a2a;
}
.panelcontrols_select_option:hover{
    background-color: #efefef;
    cursor: pointer;
    border-radius: 3px;
}
.panelcontrols_select_option h6{
    font-size: 12px;
}
