.public_pp_hero_wrapper{
    border-radius: 0px 0px 0px 200px;
    padding: 20px 0px 100px 0px;
}
.public_pp_hero_container{
    max-width: 1080px;
    margin: auto;
    padding: 0px 0px;
}
.public_pp_hero_container h3{
    font-size: 34px;
    padding: 20px 0px;
    color: white;
}
.public_pp_hero_container p{
    text-align: left;
    color: white;
    padding: 10px 0px;
    font-size: 12px;
}