.public_about_experince_container{
    max-width: 1080px;
    margin: auto;
    padding: 100px 0px 50px 0px;
}
.public_about_experince_container h3{
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
}
.public_about_experince_container p{
    text-align: center;
    width: 70%;
    margin: auto;
    padding: 50px 0px;
}