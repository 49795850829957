.labadmin_product_edit_wrapper{
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.labadmin_product_edit_container{
    width: 50%;
    position: relative;
}
.labadmin_product_edit_close{
    position: absolute;
    top: -15px;
    right: -15px;
}
.labadmin_product_edit_close button{
    background-color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid black;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.labadmin_product_edit_close button svg{
    width: 15px;
    height: 15px;
}