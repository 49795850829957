.public_about_personalized_wrapper{
    padding: 100px 0px 100px 0px;
    border-radius: 50px;
    z-index: 99;
    position: relative;
    background-color: white;
}
.public_about_personalized_wrappera{
    max-width: 1080px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.public_about_personalized_title{
    width: 40%;
}
.public_about_personalized_title h3{
    font-size: 24px;
    font-weight: 600;
}
.public_about_personalized_background{
    width: 55%;
    position: relative;
    background-color: #2F1C60;
    border-radius: 20px;
    padding-top: 40%;
}
.public_about_personalized_container{
    position: absolute;
    left: 30px;
    right: -30px;
    top: 30px;
    bottom: -30px;
    background-color: white;
    border-radius: 20px;
    border: 2px solid #2F1C60;
    display: flex;
    align-items: center;
    justify-content: center;
}
.public_about_personalized_container p{
    text-align: center;
    max-width: 90%;
}

@media screen and (max-width: 620px) {
    .public_about_personalized_wrappera{
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .public_about_personalized_title{
        width: 100%;
        padding: 0px 20px;
        margin: 20px 0px;
    }
    .public_about_personalized_title h3{
        text-align: right;
    }
    .public_about_personalized_background{
        width: 80%;
        margin: auto;
    }
    .public_about_personalized_container p{
        font-size: 10px;
    }
}
@media screen and (max-width: 460px) {
 .public_about_personalized_container p{
    font-size: 6px;
 }
}