.admin_dashboard_box_wrapper{
    margin: 5% 10% 5% 0;
    width: 90%;
    padding-top: 80%;
    position: relative;
    border-radius: 5px;
    background-color: white;
}
.admin_dashboard_box_container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.admin_dashboard_box_container h3{
    text-align: center;
    padding: 20px 0px 0px 0px;
    font-size: 70px;
    font-weight: 500;
    width: 100%;
}
.admin_dashboard_box_container p{
    text-align: center;
    font-size: 14px;
}