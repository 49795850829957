.labadmin_messages_container{
    height: 100%;
    max-height: 100%;
    position: relative;
    overflow-y: auto;
}
.labadmin_messages_chatbox{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.labadmin_messages_items{
    margin: 0px;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    padding-bottom: 50px;

}
.labadmin_messages_chatbox{
    display: flex;
    align-items: center;
    border: 2px solid black;
    padding: 10px;
    border-radius: 5px;
}
.labadmin_messages_chatbox input{
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
}
.labadmin_messages_chatbox button{
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}
.labadmin_messages_items_mymessage{
    display: flex;
    justify-content: flex-end;
}
.labadmin_messages_items_mymessage p{
    margin: 5px 0px;
    padding: 10px;
    background-color: #5C3DA9;
    border-radius: 5px;
    color: white;
}
.labadmin_messages_items_othermessage{
    display: flex;
    justify-content: flex-start;
}
.labadmin_messages_items_othermessage p{
    margin: 5px 0px;
    padding: 10px;
    background-color: #e4e4e4;
    border-radius: 5px;
    color: black;
}