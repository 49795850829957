.panelcontrols_panelbutton_table button{
    background-color: #5C3DA9;
    outline: none;
    border: 1px solid #5C3DA9;
    width: 100%;
    color: white;
    border-radius: 5px;
    padding: 5px 1px;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 5px 0px;
}
.panelcontrols_panelbutton_table button:hover{
    background-color: white;
    color: #5C3DA9;
}
.panelcontrols_panelbutton_table button p{
    font-size: 10px;
    font-weight: 500;
    padding: 0px 3px;
    color: white;
    text-align: center;
    width: 100%;
}
.panelcontrols_panelbutton_table button:hover p{
    color: #5C3DA9;
}
.panelcontrols_panelbutton_table button svg{
    width: 12px;
    height: 12px;
    margin: 0px 5px;
}
.panelcontrols_panelbutton_table button svg path{
    fill: white;
}
.panelcontrols_panelbutton_table button:hover svg path{
    fill: #5C3DA9;
}