.labadmin_orders_new_lineitems_container{
    margin: 10px 0px;
}
.labadmin_orders_new_lineitems_add{
    border-radius: 5px;
    border: 1px solid #cbcbcb;
    padding: 10px 30px 10px 10px;
    position: relative;
}
.labadmin_orders_new_lineitems_add p{
    font-size: 12px;
    font-weight: 500;
}
.labadmin_orders_new_lineitems_add button{
    outline: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    border-radius: 3px;
    background-color: #ededed;
}
.labadmin_orders_new_lineitems_list ._table{
    width: 100%;
    border-radius: 5px;
    border-bottom: 1px solid #cbcbcb;
    border-left: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
}
.labadmin_orders_new_lineitems_list ._tbody{
    width: 100%;
}
.labadmin_orders_new_lineitems_list ._tr{
    display: flex;
    width: 100%;
    cursor: pointer;
}
.labadmin_orders_new_lineitems_list ._tbody ._tr:hover{
    background-color: #f2f2f2;
}
.labadmin_orders_new_lineitems_list ._th, .labadmin_orders_new_lineitems_list ._td{
    text-align: left;
    padding: 5px;
    font-size: 12px;
}