.admin_locations_row{
    display: flex;
    justify-content: space-between;
}
.admin_locations_w33{
    width: 33%;
}
.admin_locations_formbox{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    margin: 5px 0px;
}
.admin_locations_formbox h3{
    font-size: 14px;
    font-weight: 500;
}
@media screen and (max-width: 620px) {
    .admin_locations_row{
        flex-wrap: wrap;
    }
    .admin_locations_w33{
        width: 100%;
    }
}