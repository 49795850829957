.labadmin_orders_new_lineitems_add_units_upper_container{
    height: 390px;
    position: relative;
    width: 260px;
}
.labadmin_orders_new_lineitems_add_units_upper_item{
    outline: none;
    background-color: transparent;
    border: none;
    font-size: 10px;
    cursor: pointer;
    position: absolute;
    width: 30px;
    transition: all 500ms;
}
.labadmin_orders_new_lineitems_add_units_upper_item:hover{
    transform: scale(1);
}
.labadmin_orders_new_lineitems_add_units_upper_item img{
    width: 100%;
}
.labadmin_orders_new_lineitems_add_units_upper_item svg{
    height: 25px;
    width: 25px;
}
.labadmin_orders_new_lineitems_add_units_upper_item svg path{
    fill: #9A9A9A;
}
.labadmin_orders_new_lineitems_add_units_upper_item.active svg path{
    fill: #5C3DA9;
}
.unit_item_18{
    left: 0px;
    top: 152px;
}
.unit_item_17{
    left: 5px;
    top: 122px;
}
.unit_item_16{
    left: 10.75px;
    top: 92px;
}
.unit_item_15{
    left: 22.125px;
    top: 66px;
}
.unit_item_14{
    left: 34.5px;
    top: 42px;
}
.unit_item_13{
    left: 53.875px;
    top: 25px;
}
.unit_item_12{
    left: 71.25px;
    top: 8px;
}
.unit_item_11{
    left: 96.985px;
    top: 0px;
}
.unit_item_28{
    right: 0px;
    top: 152px;
}
.unit_item_27{
    right: 5px;
    top: 122px;
}
.unit_item_26{
    right: 10.75px;
    top: 92px;
}
.unit_item_25{
    right: 22.125px;
    top: 66px;
}
.unit_item_24{
    right: 34.5px;
    top: 42px;
}
.unit_item_23{
    right: 53.875px;
    top: 25px;
}
.unit_item_22{
    right: 71.25px;
    top: 8px;
}
.unit_item_21{
    right: 96.985px;
    top: 0px;
}


.unit_item_48{
    left: 0px;
    bottom: 152px;
}
.unit_item_47{
    left: 5px;
    bottom: 122px;
}
.unit_item_46{
    left: 10.75px;
    bottom: 92px;
}
.unit_item_45{
    left: 22.125px;
    bottom: 66px;
}
.unit_item_44{
    left: 34.5px;
    bottom: 42px;
}
.unit_item_43{
    left: 53.875px;
    bottom: 25px;
}
.unit_item_42{
    left: 71.25px;
    bottom: 8px;
}
.unit_item_41{
    left: 96.985px;
    bottom: 0px;
}


.unit_item_38{
    right: 0px;
    bottom: 152px;
}
.unit_item_37{
    right: 5px;
    bottom: 122px;
}
.unit_item_36{
    right: 10.75px;
    bottom: 92px;
}
.unit_item_35{
    right: 22.125px;
    bottom: 66px;
}
.unit_item_34{
    right: 34.5px;
    bottom: 42px;
}
.unit_item_33{
    right: 53.875px;
    bottom: 25px;
}
.unit_item_32{
    right: 71.25px;
    bottom: 8px;
}
.unit_item_31{
    right: 96.985px;
    bottom: 0px;
}
.labadmin_orders_new_lineitems_add_units_upper_text{
    position: absolute;
    left: 32%;
    top: 20%;
}
.labadmin_orders_new_lineitems_add_units_lower_text{
    position: absolute;
    left: 32%;
    bottom: 20%;
}