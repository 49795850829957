.admin_billing_formbox{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}
.admin_billing_row{
    display: flex;
    justify-content: space-between;
}
.admin_billing_col33{
    width: 32.5%;
}