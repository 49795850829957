.labadmin_employee_formbox{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}
.labadmin_employee_row{
    display: flex;
    justify-content: space-between;
}
.labadmin_employee_col40{
    width: 39.5%;
}
.labadmin_employee_col60{
    width: 59.5%;
}
.labadmin_employee_col50{
    width: 49.5%;
}

.labadmin_employee_image_wrapper{
    width: 60%;
    margin: auto;
    padding-top: 60%;
    background-color: #F5F5FB;
    border-radius: 50%;
    position: relative;
}
.labadmin_employee_image_container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.labadmin_employee_image_container svg{
    width: 100px;
    height: 100px;
}
.labadmin_employee_image_container svg path{
    fill: #9a9a9a;
}