.doctor_messages_container{
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    max-width: 600px;
}
.doctor_messages_items{
    margin: 10px 0px;
    height: 40vh;
    overflow-y: auto;
}
.doctor_messages_chatbox{
    display: flex;
    align-items: center;
    border: 2px solid black;
    padding: 10px;
    border-radius: 5px;
}
.doctor_messages_chatbox input{
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
}
.doctor_messages_chatbox button{
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}
.doctor_messages_items_mymessage{
    display: flex;
    justify-content: flex-end;
}
.doctor_messages_items_mymessage p{
    margin: 5px 0px;
    padding: 10px;
    background-color: #5C3DA9;
    border-radius: 5px;
    color: white;
}
.doctor_messages_items_othermessage{
    display: flex;
    justify-content: flex-start;
}
.doctor_messages_items_othermessage p{
    margin: 5px 0px;
    padding: 10px;
    background-color: #e4e4e4;
    border-radius: 5px;
    color: black;
}