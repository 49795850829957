.admin_layout_leftbar_container{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 15vw;
    background-color: white;
    border-radius: 0px 10px 10px 0px;
    overflow-y: auto;
}
.admin_layout_leftbar_logo{
    height: 120px;
}
.admin_layout_leftbar_navigations{
    padding: 20px 0px;
}
.admin_layout_leftbar_logout_container{
    padding: 5px 10px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
}
.admin_layout_leftbar_logout_icon svg{
    width: 16px;
    height: 16px;
}
.admin_layout_leftbar_logout_icon svg path{
    fill: #c0c0c0;
}
.admin_layout_leftbar_logout_lable p{
    font-size: 14px;
    font-weight: 500;
    color: #929292;
}

.admin_layout_leftbar_logout_container:hover .admin_layout_leftbar_logout_icon svg path{
    fill: #5c3da9;
}
.admin_layout_leftbar_logout_container:hover .admin_layout_leftbar_logout_lable p{
    color: #5c3da9;
}
.admin_layout_leftbar_user_container{
    margin: 4px 0px;
}
.admin_layout_leftbar_user_image_wrapper{
    width: 60%;
    margin: auto;
    padding-top: 60%;
    background-color: #F5F5FB;
    border-radius: 50%;
    position: relative;
}
.admin_layout_leftbar_user_image_container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin_layout_leftbar_user_image_container svg{
    width: 60px;
    height: 60px;
}
.admin_layout_leftbar_user_image_container svg path{
    fill: #9a9a9a;
}
.admin_layout_leftbar_user_details{
    padding: 15px 0px;
}
.admin_layout_leftbar_user_details h4{
    text-align: center;
    text-transform: uppercase;
    color: #5c3da9;
    font-weight: 500;
    font-size: 18px;
}
.admin_layout_leftbar_user_details p{
    color: #9a9a9a;
    font-size: 12px;
    text-align: center;
}
.admin_layout_leftbar_mobile_close{
    display: none;
}

@media screen and (max-width: 620px) {
    .admin_layout_leftbar_container{
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: 70%;
        background-color: white;
        border-radius: 0px 10px 10px 0px;
        overflow-y: auto;
        z-index: 1001;
        margin-left: -100%;
        transition: all 500ms;
    }
    .admin_layout_leftbar_container.active{
        margin: 0;
    }
    .admin_layout_leftbar_mobile_close{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .admin_layout_leftbar_mobile_close button{
        outline: none;
        background-color: transparent;
        border: none;
        margin: 10px 0px;
    }
    .admin_layout_leftbar_mobile_close button svg{
        height: 40px;
        width: 40px;
    }
}