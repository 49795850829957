.labadmin_reports_formbox{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}
.labadmin_reports_formbox h3{
    font-size: 14px;
    font-weight: 500;
}
.labadmin_reports_row{
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
}
.labadmin_reports_col33{
    width: 32.5%;
}

@media screen and (max-width: 620px) {
    .labadmin_reports_row{
        display: flex;
        justify-content: space-between;
        margin: 10px 0px;
        flex-wrap: wrap;
    }
    .labadmin_reports_col33{
        width: 100%;
        margin: 10px 0px;
    }
}