.panelcontrols_panelbutton_main button{
    background-color: #5C3DA9;
    outline: none;
    border: 1px solid #5C3DA9;
    width: 100%;
    color: white;
    border-radius: 5px;
    padding: 8px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}
.panelcontrols_panelbutton_main button:hover{
    background-color: white;
    color: #5C3DA9;
}