.city_table_wrapper{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}
.city_table_search{
    position: relative;
}
.city_table_search input{
    width: 100%;
    padding: 8px 40px 8px 8px;
    outline: none;
    border: 1px solid #cbcbcb;
    border-radius: 3px;
    font-size: 12px;
}
.city_table_search input:focus{
    border: 1px solid #5C3DA9;
    border-radius: 3px;
}
.city_table_search_icon{
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    cursor: pointer;
}
.city_table_search_icon svg{
    width: 15px;
    height: 15px;
}
.city_table_search_icon svg path{
    fill: #5C3DA9;
}
.city_table_container{
    display: table;
    width: 100%;
}
.city_table_container table{
    width: 100%;
}
.city_table_container thead{
    width: 100%;
}
.city_table_container thead tr{
    padding: 5px 0px;
}
.city_table_container tbody{
    width: 100%;
}
.city_table_container tr{
    display: flex;
    width: 100%;
    cursor: pointer;
}
.city_table_container tbody tr:hover{
    background-color: #f2f2f2;
}
.city_table_container th, .city_table_container td{
    text-align: left;
    padding: 5px;
    font-size: 12px;
}
.city_table_container th{
    font-weight: 500;
}
@media screen and (max-width: 620px) {
    .city_table_wrapper{
        overflow-x: auto;
        width: 100%;
    }
    .city_table_container{
        width: 1000px !important;
    }

}