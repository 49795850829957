.public_about_quality_wrapper{
    padding: 150px 0px;
    margin-top: -50px;
    margin-bottom: -50px;
    background-size:cover;
    background-repeat: no-repeat;
    background-position:center;
    background-color: #2F1C60;
    z-index: -1;
}
.public_about_quality_container{
    max-width: 1080px;
    margin: auto;
}
.public_about_quality_container h3{
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
    color: white;
}
.public_about_quality_container p{
    text-align: center;
    width: 70%;
    margin: auto;
    padding: 50px 0px;
    color: white;
}