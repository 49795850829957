.public_subscriber_res_wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.public_subscriber_res_container{
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    width: 600px;
}
.public_subscriber_res_close{
    display: flex;
    justify-content: flex-end;
}
.public_subscriber_res_close button{
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}
.public_subscriber_res_close button svg{
    width: 35px;
    height: 35px;
}
.public_subscriber_res_message{
    padding: 50px 20px;
}
.public_subscriber_res_message p{
    font-size: 18px;
    text-align: center;
    padding: 30px;
}
.public_subscriber_res_icon_success{
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 10px;
    border: 5px solid green;
    border-radius: 50%;
}
.public_subscriber_res_icon_success svg{
    width: 80px;
    height: 80px;
}
.public_subscriber_res_icon_success svg path{
    fill: green;
}
.public_subscriber_res_icon_failed{
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 10px;
    border: 5px solid red;
    border-radius: 50%;
}
.public_subscriber_res_icon_failed svg{
    width: 80px;
    height: 80px;
}
.public_subscriber_res_icon_failed svg path{
    fill: red;
}