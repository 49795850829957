.panel_popup_wrapper{
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.panel_popup_container{
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}
.panel_popup_close{
    position: absolute;
    top: -15px;
    right: -15px;
}
.panel_popup_close button{
    background-color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid black;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.panel_popup_close button svg{
    width: 15px;
    height: 15px;
}