.public_about_innovative_wrapper{
    padding: 50px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1080px;
    margin: auto;
}
.public_about_innovative_title{
    width: 40%;
}
.public_about_innovative_title h3{
    font-size: 24px;
    font-weight: 600;
}
.public_about_innovative_background{
    width: 55%;
    position: relative;
    background-color: #2F1C60;
    border-radius: 20px;
    padding-top: 40%;
}
.public_about_innovative_container{
    position: absolute;
    left: 30px;
    right: -30px;
    top: 30px;
    bottom: -30px;
    background-color: white;
    border-radius: 20px;
    border: 2px solid #2F1C60;
    display: flex;
    align-items: center;
    justify-content: center;
}
.public_about_innovative_container p{
    text-align: center;
    max-width: 90%;
}

@media screen and (max-width: 620px) {
 .public_about_innovative_wrapper{
    flex-wrap: wrap;
 }   
 .public_about_innovative_title{
    width: 100%;
    padding: 0px 20px;
    margin: 20px 0px;
 }
 .public_about_innovative_background{
    width: 80%;
    margin: auto;
 }
 .public_about_innovative_container p{
    font-size: 10px;
 }
}
@media screen and (max-width: 460px) {
 .public_about_innovative_container p{
    font-size: 6px;
 }
}