.panelcontrols_panelinput{
    padding: 5px 0px;
}
.panelcontrols_panelinput label{
    width: 100%;
}
.panelcontrols_panelinput input{
    width: 100%;
    padding: 8px;
    margin: 2px 0px;
}
.panelcontrols_panelinput label{
    font-size: 12px;
}
.panelcontrols_panelinput input{
    outline: none;
    border: 1px solid #cbcbcb;
    border-radius: 3px;
    font-size: 12px;
}
.panelcontrols_panelinput input:focus{
    border: 1px solid #5C3DA9;
    border-radius: 3px;
}