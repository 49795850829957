.admin_dashboard_row{
    display: flex;
}
.admin_dashboard_w50{
    width: 50%;
}


@media screen and (max-width: 750px) {
    .admin_dashboard_row{
        flex-wrap: wrap;
    }
    .admin_dashboard_w50{
        width: 100%;
    }
}