.labadmin_doctor_formbox{
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}
.labadmin_doctor_row{
    display: flex;
    justify-content: space-between;
}
.labadmin_doctor_col50{
    width: 49.5%;
}