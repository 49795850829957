.public_whatwe_support_container{
    max-width: 1080px;
    padding: 100px 20px;
    margin: auto;
    margin-bottom: 100px;
}
.public_whatwe_support_details h3{
    font-size: 28px;
    text-align: center;
    font-weight: 500;
}
.public_whatwe_support_details p{
    font-size: 14px;
    text-align: center;
    padding: 20px 0px;
}
.public_whatwe_support_row{
    display: flex;
    justify-content: space-between;
    max-width: 70%;
    margin: 50px auto 0px auto;
}
.public_whatwe_support_col33{
    width: 32.5%;
}
.public_whatwe_support_category{
    width: 80%;
    margin: auto;
}
.public_whatwe_support_category h3{
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 10px 0px;
}
.public_whatwe_support_image{
    width: 100px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.public_whatwe_support_image svg{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid black;
    padding: 20px;
}
.public_whatwe_support_image img{
    width: 100%;
}

@media screen and (max-width: 620px) {
    .public_whatwe_support_row{
        flex-wrap: wrap;
    }
    .public_whatwe_support_col33{
        width: 100%;
        margin: 20px 0px;
    }
}