.labadmin_cityselector_row{
    display: flex;
    justify-content: space-between;
}
.labadmin_cityselector_col33{
    width: 32.5%;
}
@media screen and (max-width: 620px) {
    .labadmin_cityselector_row{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .labadmin_cityselector_col33{
        width: 100%;
    }
}