.public_home_hero_wrapper{
    border-radius: 0px 0px 0px 200px;
    padding: 20px 0px 100px 0px;
}
.public_home_hero_container{
    max-width: 1080px;
    margin: auto;
}
.public_home_hero_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.public_home_hero_text{
    width: 40%;
    padding-right: 20px;
}
.public_home_hero_text h3{
    font-size: 34px;
    padding: 20px 0px;
}
.public_home_hero_buttons{
    padding: 50px 0px;
}
.public_home_hero_image{
    width: 55%;
    display: flex;
    justify-content: flex-end;
}
.public_home_hero_image img{
    width: 90%;
}
.public_home_live_tacking_wrapper{
    padding: 20px 0px;
}
.public_home_live_tacking_container{
    max-width: 1080px;
    margin: auto;
}
.public_home_live_tacking_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.public_home_live_tacking_image{
    width: 30%;
}
.public_home_live_tacking_image img{
    width: 90%;
    margin-top: -50%;
    margin-left: 30%;
    transform: rotate(-15deg);
}
.public_home_live_tacking_text{
    width: 45%;
}
.public_home_live_tacking_text h3{
    font-size: 30px;
    font-weight: 500;
    padding: 10px 0px;
}


.public_home_services_wrapper{
    background-size:contain;
    background-repeat: no-repeat;
    background-position: right;
    padding: 50px 0px;
    margin: 100px 0px;
}
.public_home_services_container{
    max-width: 1080px;
    margin: auto;
}
.public_home_services_row{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}
.public_home_services_item_container{
    width: 20%;
    padding: 30px;
    border-radius: 10px;
    transition: all 200ms;
    cursor: pointer;
}
.public_home_services_item_container:hover{
    transform: scale(1.1);
}
.public_home_services_item_image img{
    width: 100%;
}
.public_home_services_item_disciption h3{
    font-size: 24px;
    text-align: center;
    padding: 20px 0px;
    font-weight: 500;
    text-transform: uppercase;
}
.public_home_services_item_disciption p{
    font-size: 10px;
    text-align: center;
}

.public_home_services_seemore_container{
    width: 20%;
    padding: 30px;
    border-radius: 10px;
    transition: all 200ms;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.public_home_services_seemore_container:hover{
    transform: scale(1.1);
}
.public_home_services_seemore_container h3{
    font-size: 24px;
    text-align: center;
    padding: 20px 0px;
    font-weight: 500;
    text-transform: uppercase;
}


.public_home_facts_background{
    background-size:cover;
    background-repeat: no-repeat;
    background-position:center;
    background-attachment: fixed;
    border-radius: 50px;
    margin-top: 200px;
    z-index: 100;
    position: relative;
    margin-bottom: -50px;
}
.public_home_facts_wrapper{
    border-radius: 50px;
    padding: 50px;
}
.public_home_facts_container{
    max-width: 1080px;
    margin: auto;
}
.public_home_facts_title{
    padding: 20px 30px;
}
.public_home_facts_title h3{
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 0px;
}
.public_home_facts_title p{
    width: 60%;
    margin: auto;
    font-size: 13px;
    text-align: center;
}

.public_home_facts_items{
    display: flex;
    align-items: stretch;
    width: 80%;
    justify-content: space-between;
    margin: 50px auto;
}
.public_home_facts_item{
    width: 30%;
}
.public_home_facts_item_image{
    display: flex;
    justify-content: center;
    padding: 20px 0px;
}
.public_home_facts_item_image svg{
    width: 50px;
    height: 50px;
}
.public_home_facts_item_about{
    padding: 10px 0px;
}
.public_home_facts_item_about h2{
    font-size: 34px;
    text-align: center;
    font-weight: 600;
}
.public_home_facts_item_about h3{
    text-align: center;
}

.public_home_testimonials_wrapper{
    background-size:cover;
    background-repeat: no-repeat;
    background-position:center;
    padding: 100px 0px;
    margin: 0px 0px -50px 0px;
}
.public_home_testimonials_container{
    max-width: 1080px;
    margin: auto;
}
.public_home_testimonials_container h3{
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    
}
.public_home_testimonials_item_container{
    padding: 0px 50px;
    margin: auto;
    max-width: 600px;
    height: 400px;
    display: flex;
    align-items: center;
}
.public_home_testimonials_item_image{
    background-size:cover;
    background-repeat: no-repeat;
    background-position:center;
    padding-top: 70%;
    width: 70%;
    margin: auto;
    border-radius: 50%;
}
.public_home_testimonials_item_details{
    padding: 20px 0px;
}
.public_home_testimonials_item_details h3{
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
}
.public_home_testimonials_item_details p{
    font-style: italic;
    font-size: 14px;
    padding: 20px 0px;
    text-align: center;
}

.swiper-button-prev , .swiper-button-next{
    padding: 40px;
    border-radius: 50%;
    background-color: #5c3da9;
    transform: scale(0.6);
}
.swiper-button-prev::after , .swiper-button-next::after{
    color: white;
}


@media screen and (max-width: 620px) {
    .public_home_hero_wrapper{
        border-radius: 0px 0px 0px 50px;
        padding: 20px 0px 150px 0px;
    }
    .public_home_hero_container{
        max-width: 1080px;
        margin: auto;
    }
    .public_home_hero_row{
        display: block;
        justify-content: space-between;
        align-items: center;
    }
    .public_home_hero_text{
        width: 100%;
        padding: 20px;
    }
    .public_home_hero_text h3{
        font-size: 34px;
        padding: 20px 0px;
    }
    .public_home_hero_buttons{
        padding: 50px 0px;
    }
    .public_home_hero_image{
        width: 100%;
        margin: 0px 0px 70px 0px;
        justify-content: center;
    }
    .public_home_hero_image img{
        width: 90%;
    }
    .public_home_live_tacking_wrapper{
        padding: 20px 0px;
    }
    .public_home_live_tacking_container{
        max-width: 1080px;
        margin: auto;
    }
    .public_home_live_tacking_row{
        display: block;
        justify-content: space-between;
        align-items: center;
    }
    .public_home_live_tacking_image{
        width: 60vw;
    }
    .public_home_live_tacking_image img{
        width: 90%;
        margin-top: -70%;
        margin-left: 30%;
        transform: rotate(-15deg);
    }
    .public_home_live_tacking_text{
        width: 100%;
        padding: 20px;
    }
    .public_home_live_tacking_text h3{
        font-size: 30px;
        font-weight: 500;
        padding: 10px 0px;
    }
    .public_home_services_wrapper{
        padding: 20px;
        margin: 20px 0px;
    }
    .public_home_services_container{
        max-width: 1080px;
        margin: auto;
    }
    .public_home_services_row{
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
    }
    .public_home_services_item_container{
        width: 100%;
        padding: 30px;
        border-radius: 10px;
        transition: all 200ms;
        cursor: pointer;
        margin: 20px 0px;
    }
    .public_home_services_item_container:hover{
        transform: scale(1.1);
    }
    .public_home_services_item_image img{
        width: 100%;
    }
    .public_home_services_item_disciption h3{
        font-size: 24px;
        text-align: center;
        padding: 20px 0px;
        font-weight: 500;
        text-transform: uppercase;
    }
    .public_home_services_item_disciption p{
        font-size: 10px;
        text-align: center;
    }
    
    .public_home_services_seemore_container{
        width: 100%;
        padding: 100px 0px;
        border-radius: 10px;
        transition: all 200ms;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .public_home_services_seemore_container:hover{
        transform: scale(1.1);
    }
    .public_home_services_seemore_container h3{
        font-size: 24px;
        text-align: center;
        padding: 20px 0px;
        font-weight: 500;
        text-transform: uppercase;
    }
    .public_home_facts_background{
        background-size:cover;
        background-repeat: no-repeat;
        background-position:center;
        background-attachment: fixed;
        border-radius: 20px;
        margin-top: 100px;
        z-index: 100;
        position: relative;
        margin-bottom: -50px;
    }
    .public_home_facts_wrapper{
        border-radius: 20px;
        padding: 20px;
    }
    .public_home_facts_container{
        max-width: 1080px;
        margin: auto;
    }
    .public_home_facts_title{
        padding: 0px 20px;
    }
    .public_home_facts_title h3{
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        padding: 10px 0px;
    }
    .public_home_facts_title p{
        width: 100%;
        margin: auto;
        font-size: 13px;
        text-align: center;
    }
    
    .public_home_facts_items{
        display: flex;
        align-items: stretch;
        width: 100%;
        justify-content: space-between;
        margin: 50px auto;
    }
    .public_home_facts_item{
        width: 30%;
    }
    .public_home_facts_item_image{
        display: flex;
        justify-content: center;
        padding: 20px 0px;
    }
    .public_home_facts_item_image svg{
        width: 50px;
        height: 50px;
    }
    .public_home_facts_item_about{
        padding: 10px 0px;
    }
    .public_home_facts_item_about h2{
        font-size: 24px;
        text-align: center;
        font-weight: 600;
    }
    .public_home_facts_item_about h3{
        text-align: center;
    }
    .public_home_testimonials_wrapper{
        background-size:cover;
        background-repeat: no-repeat;
        background-position:center;
        padding: 100px 0px;
        margin: 0px 0px -50px 0px;
    }
    .public_home_testimonials_container{
        max-width: 1080px;
        margin: auto;
    }
    .public_home_testimonials_item_container{
        padding: 0px 50px;
        margin: auto;
        max-width: 600px;
    }
    .public_home_testimonials_item_image{
        background-size:cover;
        background-repeat: no-repeat;
        background-position:center;
        padding-top: 70%;
        width: 70%;
        margin: auto;
        border-radius: 50%;
    }
    .public_home_testimonials_item_details{
        padding: 20px 0px;
    }
    .public_home_testimonials_item_details h3{
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
    }
    .public_home_testimonials_item_details p{
        font-style: italic;
        font-size: 10px;
        padding: 20px 0px;
        text-align: center;
    }
    
    .swiper-button-prev , .swiper-button-next{
        padding: 40px;
        border-radius: 50%;
        background-color: #5c3da9;
        transform: scale(0.6);
        display: none !important;
    }
    .swiper-button-prev::after , .swiper-button-next::after{
        color: white;
    }
}