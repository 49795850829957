.public_servic_aligners_wrapper{
    padding: 100px 0px;
    border-radius: 50px;
    z-index: 99;
    position: relative;
}
.public_servic_aligners_container{
    max-width: 1080px;
    margin: auto;
}
.public_servic_aligners_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.public_servic_aligners_description{
    width: 45%;
}
.public_servic_aligners_description h3{
    font-size: 34px;
    font-weight: 500;
    padding: 10px 0px;
}
.public_servic_aligners_description p b{
    font-weight: 500;
}
.public_servic_aligners_description p b span{
    font-weight: 500;
    color: #2F1C60;
}
.public_servic_aligners_images{
    width: 50%;
}
.public_servic_aligners_imageA{
    width: 50%;
    padding-top: 30%;
    background-size:cover;
    background-repeat: no-repeat;
    background-position:center;
    border-radius: 10px;
}
.public_servic_aligners_imageB{
    width: 50%;
    padding-top: 30%;
    background-size:cover;
    background-repeat: no-repeat;
    background-position:center;
    border-radius: 10px;
    margin-left: 45%;
    margin-top: -10%;
}


.public_servic_dentalimplants_wrapper{
    padding: 150px 0px;
    margin-top: -50px;
    margin-bottom: -50px;
    background-size:cover;
    background-repeat: no-repeat;
    background-position:center;
}
.public_servic_dentalimplants_container{
    max-width: 1080px;
    margin: auto;
}
.public_servic_dentalimplants_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.public_servic_dentalimplants_description{
    width: 45%;
}
.public_servic_dentalimplants_description h3{
    font-size: 34px;
    font-weight: 500;
    padding: 10px 0px;
}
.public_servic_dentalimplants_images{
    width: 50%;
}
.public_servic_dentalimplants_imageA{
    width: 50%;
    padding-top: 30%;
    background-size:cover;
    background-repeat: no-repeat;
    background-position:center;
    border-radius: 10px;
}
.public_servic_dentalimplants_imageB{
    width: 50%;
    padding-top: 30%;
    background-size:cover;
    background-repeat: no-repeat;
    background-position:center;
    border-radius: 10px;
    margin-left: 45%;
    margin-top: -10%;
}

.public_home_hero_text_claims h3{
    font-size: 18px;
    color: white;
    font-weight: 500;
}
.public_home_hero_text_claims_row{
    display: flex;
    align-items: center;
    padding: 10px 0px;
}
.public_home_hero_text_claims_icon{
    width: 40px;
}
.public_home_hero_text_claims_icon svg path{
    fill: white;
}
.public_home_hero_text_claims_text p{
    color: white;
}

@media screen and (max-width: 620px) {
    .public_servic_aligners_row{
        flex-wrap: wrap;
    }
    .public_servic_aligners_description{
        width: 100%;
        padding: 0px 20px;
        margin: 20px 0px;
    }
    .public_servic_aligners_images{
        width: 100%;
        padding: 0px 20px;
    }
    .public_servic_dentalimplants_row{
        flex-wrap: wrap;
    }
    .public_servic_dentalimplants_description{
        width: 100%;
        padding: 0px 20px;
        margin: 20px 0px;
    }
    .public_servic_dentalimplants_images{
        width: 100%;
        padding: 0px 20px;
    }

}