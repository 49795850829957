.labadmin_dashboard_orderstatusselector_container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.labadmin_dashboard_orderstatusselector_container button{
    width: 24%;
    padding: 30px 10px;
    border-radius: 5px;
    outline: none;
    border:none;
    background-color: white;
    color: black;
    cursor: pointer;
}
.labadmin_dashboard_orderstatusselector_container button:hover{
    background-color: #5C3DA9;
    color: white;
}
.labadmin_dashboard_orderstatusselector_container button.active{
    background-color: #5C3DA9;
    color: white;
}