.admin_layout_leftbar_navigation_item{
    padding: 5px 0px;
    display: flex;
    align-items: center;
    position: relative;
    margin: 15px 0px 0px 0px;
}
.admin_layout_leftbar_navigation_icon{
    width: 80px;
    display: flex;
    justify-content: center;
}
.admin_layout_leftbar_navigation_icon svg{
    width: 16px;
    height: 16px;
}
.admin_layout_leftbar_navigation_icon svg path{
    fill: #c0c0c0;
}
.admin_layout_leftbar_navigation_lable p{
    font-size: 14px;
    font-weight: 500;
    color: #929292;
}

.admin_layout_leftbar_navigation_item:hover .admin_layout_leftbar_navigation_icon svg path{
    fill: #5c3da9;
}
.admin_layout_leftbar_navigation_item:hover .admin_layout_leftbar_navigation_lable p{
    color: #5c3da9;
}

.active .admin_layout_leftbar_navigation_icon svg path{
    fill: #5c3da9;
}
.active .admin_layout_leftbar_navigation_lable p{
    color: #5c3da9;
}

.admin_layout_leftbar_navigation_item_bar{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 3px;
    background-color: #5c3da9;
}
.admin_layout_leftbar_navigation_item_subnav_container{
    padding: 5px 0px;
}
.admin_layout_leftbar_navigation_item_subnav_item{
    padding: 5px 0px 5px 80px;
}
.admin_layout_leftbar_navigation_item_subnav_item p{
    font-size: 14px;
    font-weight: 500;
    color: #929292;
}
.admin_layout_leftbar_navigation_item_subnav_item:hover p{
    color: #5c3da9;
}
.admin_layout_leftbar_navigation_item_subnav_item.active p{
    color: #5c3da9;
}